<template>
	<div class="p-grid">

        <div class="p-col-12 p-md-4">
            <div class="card p-fluid">
                <h5>Your Help Center</h5>
                <div class="para">                    
                    Use this form to let us know of any issues or questions you have.
                </div>
                <div class="para">                    
                    These are routed to the appropriate ArenaCX personnel, such as Customer Success, our Tech Team, or others.
                </div>
            </div>
        </div>
    
        <div class="p-col-12 p-md-8">

			<div class="card p-fluid">
                <div class="p-formgrid p-grid">
					<div class="p-field p-col">
                            <label for="name">Name</label>
                            <InputText id="name" type="text" />
					</div>
					<div class="p-field p-col">
                            <label for="email">Email</label>
                           <InputText id="email" type="email" />
					</div>

				</div>
                <div class="p-formgrid p-grid">
					
					<div class="p-field p-col">
                            <label for="company">Company</label>
                            <InputText id="company" type="text" />
					</div>
					<div class="p-field p-col">
                            <label for="urgency">Urgency</label>
                            <Dropdown id="urgency" v-model="urgencySelected" :options="urgencyOptions" optionLabel="name"></Dropdown>
					</div>
				</div>
 

				<div class="p-field p-col">
					<label for="description" >Describe the issue</label>
					<div  >
						<Textarea id="description" type="text" style="height:200px"/>
					</div>
				</div>				
                <div class="p-field p-grid">
					<div class="p-col-12 p-mb-10 p-md-10 p-mb-md-0"></div>
					<div class="p-col-12 p-md-2" >
						<Button label="Submit"></Button>
					</div>
				</div>
				
			</div>

			

			
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				urgencyOptions: [
					{name: '1 - Most Urgent', code: '1'},
					{name: '2', code: '2'},
					{name: '3', code: '3'},
					{name: '4', code: '3'},
					{name: '5 - Least Urgent', code: '3'}
				],
				urgencySelected: null,
			}
		}
	}
</script>

<style scoped>
    .para {
        margin: 18px;
    }
</style>